import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useRouter } from "next/router";
import { Grid } from "semantic-ui-react";
import Chatbot from "./customerservicesbot";
import { login, sendOTP } from "./api/authenticate.js";
import Link from "next/link";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoginData } from "../context/context.js";
import url from "../config/config.json";
import axios from 'axios';

import {
  useToast,
  Icon,
  Button,
  Box,
  Container,
  Text,
  FormControl,
  Heading,
  Input,
} from "@chakra-ui/react";
import { FaEnvelope, FaLock, FaArrowRight, FaSpinner } from "react-icons/fa";
export default function LoginPage() {
  const toast = useToast();
  const { setUserId, setUserType } = useLoginData();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let timer;
    if (isSubmitting) {
      timer = setTimeout(() => {
        setIsSubmitting(false);
      }, 3000); // 3 seconds delay before re-enabling the login button
    }
    return () => clearTimeout(timer);
  }, [isSubmitting]);
  const router = useRouter();
  const [isShowLoginButton, setIsShowLoginButton] = useState(false);
  const [isSendLoginDisabled, setIsSendLoginDisabled] = useState(false);

  const [logresponse, setLogResponse] = useState(null);

  const [errorResponse, setErrorResponse] = useState(null);

  const [useriddata, setUserIdData] = useState(null);

  const [usertypedata, setUserTypeData] = useState(null);

  const handleUserlogin = async (values) => {
    try {
      setIsSendLoginDisabled(true);
      const result = await login(values.emailOrMobile, values.otp);
      let userDetails = result.login || null;
            if (userDetails) {
        if (userDetails.user_role === "candidate") {
          sessionStorage.setItem("userId", JSON.stringify(userDetails.user_id));
          sessionStorage.setItem(
            "userType",
            JSON.stringify(userDetails.user_role)
          );
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Login successful!",
            showConfirmButton: false,

            timer: 1500,
          });
          setUserId(userDetails.user_id);
          setUserType(userDetails.user_role);

          router.push("/userProfile");
          setIsSendLoginDisabled(false);

          //call on log action
          <div>
          {logresponse ? (
          console.log(<pre>{JSON.stringify(logresponse, null, 2)}</pre>)
          ) : (
          console.log(<p>Error</p>)
          )}
          </div>
          
          // router.reload(); // Reload the user profile page after navigation
        } else if (userDetails.user_role === "recruiter") {
          if (userDetails.approval_status !== "true") {
            toast({
              title: "You have not been Approved yet!",
              description: "Please try again after some time",
              variant: "solid",
              duration: 3000,
              isClosable: true,
            });
            router.push("/");
          } else {
            sessionStorage.setItem(
              "userId",
              JSON.stringify(userDetails.recruiter_id)
            );
            setUserId(userDetails.recruiter_id);
            setUserType(userDetails.user_role);
            sessionStorage.setItem(
              "userType",
              JSON.stringify(userDetails.user_role)
            );
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Login successful!",
              showConfirmButton: false,
              timer: 1500,
            });
            router.push("/rectruiter");
            setIsSendLoginDisabled(false);

       //calling of console log
       <div>
       {logresponse ? (
         console.log(<pre>{JSON.stringify(logresponse, null, 2)}</pre>)
       ) : (
         console.log(<p>Error</p>)
       )}
      </div>

          }
        } else if (userDetails.user_role === "admin") {
          setUserId(userDetails.admin_id);
          setUserType(userDetails.user_role);
          sessionStorage.setItem(
            "userId",
            JSON.stringify(userDetails.admin_id)
          );
          sessionStorage.setItem(
            "userType",
            JSON.stringify(userDetails.user_role)
          );
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Login successful!",
            showConfirmButton: false,
            timer: 1500,
          });
          router.push("/admin");
          setIsSendLoginDisabled(false);

        } else if (userDetails.user_role === "recruitment_manager") {
          setUserId(userDetails.rem_id);
          setUserType(userDetails.user_role);
          sessionStorage.setItem("userId", JSON.stringify(userDetails.rem_id));
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Login successful!",
            showConfirmButton: false,
            timer: 1500,
          });
          sessionStorage.setItem(
            "userType",
            JSON.stringify(userDetails.user_role)
          );
          // router.push("/recruiterManager");
          router.push("/accountManager");

          setIsSendLoginDisabled(false);
        } else if (userDetails.user_role === "candidate_manager") {
          setUserId(userDetails.cam_id);
          setUserType(userDetails.user_role);
          sessionStorage.setItem("userId", JSON.stringify(userDetails.cam_id));
          sessionStorage.setItem(
            "userType",
            JSON.stringify(userDetails.user_role)
          );
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Login successful!",
            showConfirmButton: false,
            timer: 1500,
          });
          router.push("/candidateManager");
          setIsSendLoginDisabled(false);

          // call log action
          <div>
          {logresponse ? (
          console.log(<pre>{JSON.stringify(logresponse, null, 2)}</pre>)
          ) : (
          console.log(<p>Error</p>)
          )}
          </div>
        
        } else if (userDetails.user_role === "sales_manager") {
         
          setUserId(userDetails.sales_manager_id );
          setUserType(userDetails.user_role);
          sessionStorage.setItem("userId", JSON.stringify(userDetails.sales_manager_id));
          sessionStorage.setItem(
            "userType",
            JSON.stringify(userDetails.user_role)
          );
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Login successful!",
            showConfirmButton: false,
            timer: 1500,
          });
          router.push("/salesManager");
          setIsSendLoginDisabled(false);
        }
      }
    } catch (error) {
      setIsSendLoginDisabled(false);
      console.log(error);
      // login data validation
      if (
        error.message === "user not found" ||
        error.message === "user or recruiter not found"
      ) {
        // Swal.fire({
        //   position: "center",
        //   icon: "error",
        //   title: "This account doesn’t exist, Please signUp",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        toast({
          title: "This account doesn’t exist, Please signUp",
          status: "error",
          position: "top",
          isClosable: true,
          duration: 2000,
        });

        <div>
      {errorResponse ? (
        console.log(<pre>{JSON.stringify(errorResponse, null, 2)}</pre>)
      ) : (
        console.log(<p>Error</p>)
      )}
     </div>

      } else if (error.message === "OTP incorrect") {
        // Swal.fire({
        //   position: "center",
        //   icon: "error",
        //   title: "Incorrect OTP, Please try again",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        toast({
          title: "Incorrect OTP, Please try again",
          status: "error",
          position: "top",
          isClosable: true,
          duration: 2000,
        });

        //call of log action
        <div>
      {errorResponse ? (
        console.log(<pre>{JSON.stringify(errorResponse, null, 2)}</pre>)
      ) : (
        console.log(<p>Error</p>)
      )}
     </div>


      } else {
        console.log(error);

        //call log action
        <div>
      {errorResponse ? (
        console.log(<pre>{JSON.stringify(errorResponse, null, 2)}</pre>)
      ) : (
        console.log(<p>Error</p>)
      )}
     </div>  
        }
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [isSendOTPDisabled, setIsSendOTPDisabled] = useState(true);
  const [isEmailCorrect, setIsEmailCorrect] = useState(false);
  const handleSendOTP = async (values) => {
    try {
      // Disable the button to prevent multiple clicks
      setIsSendOTPDisabled(true);
      const result = await sendOTP(values.emailOrMobile);
      setIsShowLoginButton(true);
      // Enable the button after a certain period of time (e.g., 5 seconds)
      setTimeout(() => {
        setIsSendOTPDisabled(false);
      }, 5000);
    } catch (error) {
      setIsSendOTPDisabled(false);
      console.log(error);
    }
  };
  const validationSchema = Yup.object().shape({
    emailOrMobile: Yup.string().required("Email or Mobile is required"),
    otp: Yup.string().required("OTP is required"),
  });
  const formik = useFormik({
    initialValues: {
      emailOrMobile: "",
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUserlogin(values);
    },
  });

//new changes [Log Action]
useEffect(() => {
  const fetchData = async () => {
    try {
      const baseUrl = url.baseUrl;
      const logActionUrl = `${baseUrl}/log_action`;

      const useriddata = JSON.parse(sessionStorage.getItem("userId"));
      setUserIdData(useriddata);

      const usertypedata = JSON.parse(sessionStorage.getItem("userType"));
      setUserTypeData(usertypedata);

      const formData = [
        { key: "id_type", value: usertypedata },
        { key: "id", value: useriddata },
        { key: "action", value: "Login Successfully" },
      ];

      // Convert formData to x-www-form-urlencoded format
      const data = new URLSearchParams();
      formData.forEach(({ key, value }) => {
        data.append(key, value);
      });

      // Make the POST request
      const consoleresponse = await axios.post(logActionUrl, data);
      setLogResponse(consoleresponse.data);
    } catch (error) {
      console.error("Error:", error.message);

      // If there's an error, update the action to "Error in login"
      const formDataError = [
        { key: "id_type", value: usertypedata },
        { key: "id", value: useriddata },
        { key: "action", value: "Error in login" },
      ];

      // Convert formDataError to x-www-form-urlencoded format
      const dataError = new URLSearchParams();
      formDataError.forEach(({ key, value }) => {
        dataError.append(key, value);
      });

      // Make another POST request to log the error
      const errorResponse = await axios.post(logActionUrl, dataError);
      setErrorResponse(errorResponse.data);
    }
  };

  fetchData();
}, []);  



  return (
    <Container>
      <Box
        border="1px solid #ccc"
        borderRadius="md"
        p="4"
        maxWidth="500px"
        margin="auto"
        mt="150"
      >
        <Grid
          textAlign="center"
          minH="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid.Column maxWidth="450px">
            <Heading
              as="h2"
              color="blue.500"
              textAlign="center"
              mb="4"
              fontFamily="Arial"
            >
              Login to your account
            </Heading>
            <FormControl size="lg" onSubmit={formik.handleSubmit}>
              <Input
                type="email"
                icon={<Icon as={FaEnvelope} />}
                placeholder="Enter your E-mail"
                id="emailOrMobile"
                value={formik.values.emailOrMobile}
                onChange={(e) => {
                  formik.setFieldValue("emailOrMobile", e.target.value);
                  setIsEmailCorrect(
                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)
                  );
                }}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.touched.emailOrMobile && !!formik.errors.emailOrMobile
                }
                required
              />
              {formik.touched.emailOrMobile && (
                <Text color="red" mt="2" fontSize="sm">
                  {formik.errors.emailOrMobile}
                </Text>
              )}

              {!isShowLoginButton && (
                <Button
                  colorScheme="blue"
                  variant="solid"
                  size="lg"
                  mt="4"
                  onClick={() => {
                    if (isEmailCorrect) {
                      handleSendOTP(formik.values);
                    } else {
                      // handle invalid email input
                    }
                  }}
                  loadingText="Sending OTP..."
                  isDisabled={!isEmailCorrect}
                  width="100%"
                >
                  Send OTP
                </Button>
              )}
              {isShowLoginButton && (
                <>
                  <Input
                    type="text"
                    icon={<Icon as={FaLock} />}
                    placeholder="Enter OTP"
                    id="otp"
                    value={formik.values.otp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.otp && !!formik.errors.otp}
                    required
                  />
                  {formik.touched.otp && (
                    <Text color="red" mt="2" fontSize="sm">
                      {formik.errors.otp}
                    </Text>
                  )}
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    size="lg"
                    type="submit"
                    isLoading={isSendLoginDisabled}
                    rightIcon={
                      isSendLoginDisabled ? <Icon as={FaSpinner} /> : null
                    }
                    mt="4"
                    loadingText="Logging in..."
                    width="100%"
                    onClick={formik.handleSubmit}
                    disabled={isSendLoginDisabled} // Disable the "Login" button when submitting the form
                  >
                    Login
                  </Button>
                </>
              )}
            </FormControl>
            <Text mt="4">
              Don't have an account?{" "}
              <Link href="/signUp" color="blue">
                Sign Up
              </Link>
            </Text>
          </Grid.Column>
        </Grid>
      </Box>
      <Chatbot />
    </Container>
  );
                  }        